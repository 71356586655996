<template>
  <!-- HERO PAGE AND NAV-BAR -->
  <Herowall></Herowall>
  <!-- <Hero></Hero> -->

  <!-----------SERVICES SECTION------------->
  <v-container class="container-sm services">
    <Services></Services>

    <About></About>

    <Word></Word>

    <ChooseUs></ChooseUs>

    <Testimonial></Testimonial>

    <TutorList></TutorList>

    <TheMessage></TheMessage>
  </v-container>

  <ContactUs></ContactUs>

  <Footer></Footer>
</template>

<script setup>
// defineRouteRules({
//   prerender: true
// })
</script>

<script>
import "animate.css";
export default {
  data() {
    return {
      requestForm: false,
      products: null,
      length: 3,
      onboarding: 0,
      responsiveOptions: [
        {
          breakpoint: "1400px",
          numVisible: 2,
          numScroll: 1,
        },
        {
          breakpoint: "1199px",
          numVisible: 3,
          numScroll: 1,
        },
        {
          breakpoint: "767px",
          numVisible: 2,
          numScroll: 1,
        },
        {
          breakpoint: "575px",
          numVisible: 1,
          numScroll: 1,
        },
      ],
    };
  },

  methods: {
    formToggle() {
      this.requestForm = !this.requestForm;
    },
  },
};
</script>

<style scoped>
.title {
  /* padding-top: 20vh; */
  /* font-size: 2.3vh; */
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.paragraph {
  font-size: 1.3rem;
  padding: 5% 7%;
}

.testimony {
  padding: 7% 3%;
}

.reqBtn {
  margin: 0 2rem 0;
}

.carousel__item {
  min-height: 200px;
  width: 100%;
  background-color: var(--vc-clr-primary);
  color: var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__slide {
  padding: 10px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 5px solid white;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .paragraph {
    font-size: 0.99rem;
  }

  .title {
    font-size: 1.7vh;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Landscape */
@media only screen and (orientation: landscape) {
}
</style>
