<template>
  <v-sheet elevation="5" class="d-flex justify-center align-center py-12">
    <v-window v-model="window" show-arrows>
      <template v-slot:prev="{ props }">
      <v-btn
        color="green-darken-4"
        @click="props.onClick"
      >
        <span class="mdi mdi-arrow-left-bold-box-outline"></span>
      </v-btn>
    </template>
    <template v-slot:next="{ props }">
      <v-btn
        color="green-darken-4"
        @click="props.onClick"
      >
 <span class="mdi mdi-arrow-right-bold-box-outline"></span>
      </v-btn>
    </template>
      <h1 class="choose-title">Testimonials</h1>
      <v-window-item>
        <p class="parent-quote text-body-1 py-4">
          Lifeline is teaching reading to my children. The tutor assigned to me
          doing a good job and I find their service to be affordable. I commend
          Lifeline for the good job they are doing.
        </p>
        <v-card subtitle="What Our Parents Say:"> </v-card>
      </v-window-item>

      <v-window-item>
        <p class="parent-quote text-body-1 py-4">
          "My daughters are being taught by two tutors from Lifeline. I
          appreciate their professionalism and dedication. My daughters enjoy
          their teaching and have improved greatly."
        </p>
        <v-card subtitle="What Our Parents Say:"> </v-card>
      </v-window-item>

      <v-window-item>
        <p class="parent-quote text-body-1 py-4">
          "My son is currently preparing for the Basic Edu. Certificate Exam.
          (B.E.C.E). Lifeline provided two tutors for different subjects and
          their prices are affordable. I highly recommend them to you too."
        </p>
        <v-card subtitle="What Our Parents Say:"> </v-card>
      </v-window-item>
    </v-window>
  </v-sheet>
</template>

<script>
export default {
  name: "Testimonial Component",
  data() {
    return {
      length: 3,
      window: 0,
      items: [
        {
          label: "Refresh",
          icon: "pi pi-refresh",
        },
        {
          label: "Search",
          icon: "pi pi-search",
        },
        {
          separator: true,
        },
        {
          label: "Delete",
          icon: "pi pi-times",
        },
      ],
    };
  },
};
</script>

<style scoped>
.parent-quote {
  font-size: 20px;
  line-height: 1.5;
  padding: 5rem 8rem;
  /* margin: 2rem 0; */
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .parent-quote {
    font-size: 12px;
    line-height: 1.5;
    padding: 0.5rem;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}
</style>
